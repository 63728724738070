<template>
  <v-row>
    <v-col cols="12" class="pt-0">
      <div class="text-center">
        <v-avatar size="140">
          <v-img
            :lazy-src="$defaultImage"
            :src="getProfileImage() ? getProfileImage() : $defaultImage"
            aspect-ratio="1"
            class="margin-auto custom-grey-border custom-border-radius-50"
            transition="fade-transition"
          >
          </v-img>
        </v-avatar>
      </div>
      <div class="mt-5">
        <table width="100%">
          <tr>
            <th
              colspan="2"
              class="grey lighten-4 font-size-19 color-custom-blue py-1"
            >
              Basic Information
            </th>
          </tr>
          <tr>
            <td class="font-size-18 font-weight-500 py-2" style="width: 120px">
              Equipment No.
            </td>
            <td class="font-size-18 py-2">
              <template v-if="detail && detail.barcode">
                <v-chip color="blue darken-4 mr-2" label small outlined>
                  {{ detail.barcode }}
                </v-chip>
                <showBarcode
                  :barcodeUrl="detail.barcode_image"
                  :barcode-text="detail.barcode"
                  title="Barcode"
                />
              </template>
              <template v-else> no Equipment No. </template>
            </td>
          </tr>
          <tr>
            <td
              valign="top"
              class="font-size-18 font-weight-500 py-2"
              style="width: 150px"
            >
              Name
            </td>
            <td class="font-size-18 py-2">
              <template v-if="detail && detail.name">
                <span class="text-capitalize">{{ detail.name }}</span>
              </template>
              <template v-else> no Name </template>
            </td>
          </tr>
          <tr>
            <td class="font-size-18 font-weight-500 py-2" style="width: 120px">
              Equipment Serial No.
            </td>
            <td class="font-size-18 py-2">
              <template v-if="detail && detail.serial_no">
                <v-chip color="blue darken-4 mr-2" label small outlined>
                  {{ detail.serial_no }}
                </v-chip>
                <showBarcode
                  :barcodeUrl="detail.serial_image"
                  :barcode-text="detail.serial_no"
                  title="Barcode"
                />
              </template>
              <template v-else> no Equipment Serial No. </template>
            </td>
          </tr>
          <!-- <tr>
            <td class="font-size-18 font-weight-500 py-2">Serial No.</td>
            <td class="font-size-18 py-2">
              <template v-if="detail && detail.serial_no">
                <v-chip outlined label small color="green">
                  {{ detail.serial_no }}
                </v-chip>
              </template>
              <template v-else> no Serial no. </template>
            </td>
          </tr>
          <tr>
            <td class="font-size-18 font-weight-500 py-2">
              Serial No. Barcode
            </td>
            <td class="font-size-18 py-2">
              <showBarcode
                title="Serial No. barcode"
                :barcode-url="detail.serial_image"
              ></showBarcode>
            </td>
          </tr> -->
          <tr>
            <td class="font-size-18 font-weight-500 py-2">Batch Number</td>
            <td class="font-size-18 py-2">
              <template v-if="detail && detail.batch_number">
                <span class="text-capitalize">{{ detail.batch_number }}</span>
              </template>
              <template v-else>
                <span class="text-muted">no batch number </span></template
              >
            </td>
          </tr>
          <tr>
            <td class="font-size-18 font-weight-500 py-2">Selling Price</td>
            <td class="font-size-18 py-2">
              <template v-if="detail && detail.selling_price">
                <!-- <span class="text-capitalize">{{ formatMoney(detail.selling_price) }}</span> -->
                <v-chip outlined text-color="" label small color="blue">
                  {{ formatMoney(detail.selling_price) }}
                </v-chip>
              </template>
              <template v-else> no Selling Price </template>
            </td>
          </tr>
          <tr
            class=""
            v-if="detail && detail.customer && detail.customer.company_name"
          >
            <td
              valign="top"
              class="font-size-18 font-weight-500 py-2"
              style="min-width: 120px !important; width: 50% !important"
            >
              Company Name
            </td>
            <td class="font-size-18 py-2">
              <template
                v-if="detail && detail.customer && detail.customer.company_name"
              >
                <span class="text-capitalize">
                  {{ detail.customer.company_name }}</span
                >
              </template>
              <template v-else>
                <em class="text-muted"> no company name</em>
              </template>
            </td>
          </tr>
          <tr>
            <th
              colspan="2"
              class="grey lighten-4 font-size-19 color-custom-blue py-1"
            >
              Site Location
            </th>
          </tr>
          <tr
            v-if="
              detail &&
              detail.property &&
              detail.property.property_address &&
              detail.main_type != 'main_company'
            "
          >
            <!-- <td class="font-size-18 font-weight-500 py-2 d-flex">
                  Site Location
                </td> -->
            <td class="font-size-18 py-2" colspan="2">
              <template
                v-if="
                  detail && detail.property && detail.property.property_address
                "
              >
                {{ getFormattedProperty(detail.property) }}
              </template>
              <template v-else>
                <em class="text-muted"> no site location </em>
              </template>
            </td>
          </tr>
          <tr
            v-if="detail && detail.main_type == 'main_company' && detail.entity"
          >
            <!-- <td class="font-size-18 font-weight-500 py-2">
                  Site Location
                </td> -->
            <td class="font-size-18 py-2" colspan="2">
              <template v-if="detail && detail.entity">
                {{ getFormattedCompany() }}
              </template>
              <template v-else>
                <em class="text-muted"> no site location </em>
              </template>
            </td>
          </tr>
          <tr v-if="detail.tags && detail.tags.length > 0">
            <th
              colspan="2"
              class="font-size-19 color-custom-blue py-2 grey lighten-4"
            >
              Tags
            </th>
          </tr>
          <tr v-if="detail.tags && detail.tags.length > 0">
            <td class="font-size-18 py-2" colspan="2">
              <template v-if="detail.tags && detail.tags.length > 0">
                <v-chip
                  v-for="(row, index) in detail.tags"
                  :key="index"
                  style="height: 26px"
                  class="mr-2"
                  text-color="white"
                  :color="row.color"
                  >{{ row.text }}</v-chip
                >
              </template>
              <em v-else class="text-muted"> no tags</em>
            </td>
          </tr>
        </table>
        <table width="100%">
          <tr>
            <th
              colspan="2"
              class="font-size-19 color-custom-blue py-2 grey lighten-4"
            >
              Dates Info
            </th>
          </tr>
          <tr class="">
            <td class="font-size-18 font-weight-500 py-2" style="width: 150px">
              Installation Date
            </td>
            <td class="font-size-18 py-2">
              <template v-if="detail && detail.installation_date">
                {{ formatDate(detail.installation_date) }}
              </template>
              <template v-else>
                <em class="text-muted"> no Installation Date </em>
              </template>
            </td>
          </tr>
          <tr class="">
            <td class="font-size-18 font-weight-500 py-2">Commission Date</td>
            <td class="font-size-18 py-2">
              <template v-if="detail && detail.activation_date">
                {{ formatDate(detail.activation_date) }}
              </template>
              <template v-else>
                <em class="text-muted"> no Commission Date </em>
              </template>
            </td>
          </tr>
          <tr class="">
            <td class="font-size-18 font-weight-500 py-2">End Life</td>
            <td class="font-size-18 py-2">
              <template v-if="detail && detail.end_life">
                {{ formatDate(detail.end_life) }}
                <!--   <span
                  class="red--text font-weight-700"
                  v-if="totalDays(detail.end_life) > 0"
                >
                  ( {{ totalDays(detail.end_life) }} Days )
                </span> -->
              </template>
              <template v-else>
                <em class="text-muted"> no End Life </em>
              </template>
            </td>
          </tr>
        </table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment-timezone";
// import Status from "@/view/pages/partials/Status.vue";
// import Barcode from "@/view/pages/partials/Barcode.vue";
import { toSafeInteger } from "lodash";
/* import { QUERY } from "@/core/services/store/request.module"; */
import showBarcode from "@/view/components/showBarcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  data() {
    return {
      equipment: 0,
      dataLoading: true,
      chartOptions: {
        chart: {
          id: "equipment-summary",
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif",
          },
        },
      },
      series: [],
      tOneFields: [
        {
          title: "Brand",
          key: "brand",
          child: false,
        },
        {
          title: "Model",
          key: "model",
          child: false,
        },
        {
          title: "Category",
          key: "category",
          child: false,
        },
        {
          title: "Contact For Item",
          key: "company_number",
          child: false,
        },
        {
          title: "Description",
          key: "description",
          child: false,
        },
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.equipment = this.lodash.toSafeInteger(param.id);
        this.dataLoading = false;
      },
    },
  },
  methods: {
    getProfileImage() {
      if (!this.detail.image) {
        return null;
      }
      return this.detail.image;
    },
    getStatusText(data) {
      if (toSafeInteger(data) == 1) {
        return "Draft";
      } else if (toSafeInteger(data) == 2) {
        return "Active";
      } else if (toSafeInteger(data) == 3) {
        return "Extended";
      } else if (toSafeInteger(data) == 4) {
        return "Terminated";
      } else if (toSafeInteger(data) == 5) {
        return "Expired";
      }
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }

      if (row.country) {
        tempArr.push(row.country);
      }
      return tempArr.join(", ");
    },
    getFormattedCompany() {
      let tempArr = [];
      if (this.detail.entity.unit_no) {
        tempArr.push(this.detail.entity.unit_no);
      }
      if (this.detail.entity.street_1) {
        tempArr.push(this.detail.entity.street_1);
      }
      if (this.detail.entity.street_2) {
        tempArr.push(this.detail.entity.street_2);
      }

      if (this.detail.entity.zip_code) {
        tempArr.push(this.detail.entity.zip_code);
      }
      if (this.detail.entity.landmark) {
        tempArr.push(this.detail.entity.landmark);
      } else {
        tempArr.push("Singapore");
      }
      /*   if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }
      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }
      if (this.lodash.isEmpty(row.country) === false) {
        tempArr.push(row.country);
      } */
      return tempArr.join(", ");
    },
    getStatusColor(data) {
      if (toSafeInteger(data) == 1) {
        return "blue";
      } else if (toSafeInteger(data) == 2) {
        return "green";
      } else if (toSafeInteger(data) == 3) {
        return "orange";
      } else if (toSafeInteger(data) == 4) {
        return "red";
      } else if (toSafeInteger(data) == 5) {
        return "teal";
      }
    },
  },
  mounted() {
    /*  this.getSummary(); */
  },
  computed: {
    equipmentActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.detail) === false) {
        return _this.detail.activated == 1 ? true : false;
      }
      return false;
    },
  },
  components: {
    showBarcode,
    // Status,
    // Barcode,
  },
};
</script>
